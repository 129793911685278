import React from 'react'
import styles from './BuildCityButton.module.scss'

export default class BuildCityButton extends React.Component<{
  active?: boolean,
  onclick?: () => void,
  style?: React.CSSProperties
}, {}> {
  render () {
    return <button disabled={!this.props.active} style={this.props.style} onClick={this.props.onclick} className={`${styles.BuildCityButton} ${this.props.active ? `${styles.BuildCityButtonNormal}` : ''}`}>
      ПОСТРОИТЬ РАЙОН
    </button>
  }
}
