import React from 'react'
import './App.scss'

import { TileImage, TileImageFactory } from './models/TileImage'
import { GameMap } from './models/GameMap'
import { GameObject } from './models/GameObject'

import { Tile } from './models/Tile'
import BuildCityButton from './components/BuildCityButton/BuildCityButton'
import Result from './components/Result/Result'
import Building from './models/Building'

import Menu from './components/Menu/Menu'
import CityBuilder from './components/CityBuilder/CityBuilder'
import RotateScreen from './components/RotateScreen/RotateScreen'
import Dropdown from './components/Dropdown/Dropdown'
import SoundsLoader from './components/SoundsLoader/SoundsLoader'
import LoadingScreen from './components/LoadingScreen/LoadingScreen'

import MusicPlayer from './components/MusicPlayer/MusicPlayer'

class App extends React.Component<{}, {
    showLoading: boolean,
    showMenu: boolean,
    showGame: boolean,
    showResults: boolean,
    currentBuilding: number,
    mouseDown: boolean,
    mouseMove: boolean,
    lastMouseX: number,
    lastMouseY: number,
    touchLastDate: Date,
    buildingBuilt: boolean,
    showBuildingsList: boolean,

    selectedCell: any,
    showActionsList: boolean,
    restart: boolean,

    selectKey: React.Key,
}> {
  mapWidth = 16
  mapHeight = 16

  buildings = [] as Array<Building>

  allTiles = [
    '/tiles/road.png',
    '/tiles/road_selected.png',
    '/tiles/background/background_tile_1.png',
    '/tiles/background/background_tile_2.png',
    '/tiles/background/background_tile_3.png',
    '/tiles/background/background_tile_4.png',
    '/tiles/background/background_tile_5.png',
    '/tiles/background/background_tile_6.png',
    '/tiles/background/background_tile_7.png',
    '/tiles/background/background_tile_8.png',
    '/tiles/background/background_tile_9.png',
    '/tiles/background/background_tile_10.png',
    '/tiles/background/background_tile_11.png',
    '/tiles/buildings/residential_big.png',
    '/tiles/buildings/residential_small.png',
    '/tiles/buildings/kindergarten.png',
    '/tiles/buildings/water_park.png',
    '/tiles/buildings/water_park_hover.png',
    '/tiles/buildings/sports.png',
    '/tiles/buildings/sports_hover.png',
    '/tiles/buildings/metro.png',
    '/tiles/buildings/metro_hover.png',
    '/tiles/buildings/hospital.png',
    '/tiles/buildings/green_park.png',
    '/tiles/buildings/school.png',
    '/tiles/buildings/school_hover.png',
    '/tiles/buildings/skyscraper.png',
    '/tiles/buildings/skyscraper_hover.png',
    '/tiles/buildings/innovative_industry.png',
    '/tiles/buildings/small_industry.png',
    '/tiles/buildings/mall.png',
    '/tiles/buildings/mall_hover.png',
    '/tiles/buildings/business_park.png',
    '/tiles/buildings/technopolis.png',
    '/tiles/buildings/technopolis_hover.png',
    '/tiles/buildings/residential_big_hover.png',
    '/tiles/buildings/residential_small_hover.png',
    '/tiles/buildings/innovative_industry_hover.png',
    '/tiles/buildings/small_industry_hover.png',
    '/tiles/buildings/business_park_hover.png',
    '/tiles/buildings/kindergarten_hover.png',
    '/tiles/buildings/green_park_hover.png',
    '/tiles/buildings/hospital_hover.png'
  ]

  canvas = null as any
  ctx = null as any
  gameMap = null as any
  tileImages = null as any

  soundsRef: React.RefObject<SoundsLoader>
  resultsRef: React.RefObject<Result>

  constructor (props: Object) {
    super(props)

    this.state = {
      showLoading: true,
      showMenu: false,
      currentBuilding: 0,
      showGame: false,
      showResults: false,
      mouseDown: false,
      mouseMove: false,
      lastMouseX: 0,
      lastMouseY: 0,
      touchLastDate: new Date(),
      buildingBuilt: false,
      selectedCell: null,
      showBuildingsList: false,
      showActionsList: false,
      restart: false,
      selectKey: 0
    }

    this.soundsRef = React.createRef()
    this.resultsRef = React.createRef()
  }

  async componentDidMount () {
    await this.init()
  }

  async init () {
    this.tileImages = new TileImageFactory(this.allTiles)

    await this.tileImages.init()
  }

  // Основные функции движка

  updateGame (ctx: CanvasRenderingContext2D, gameMap: GameMap, offsetX: number, offsetY: number) {
    this.updateCanvasSize(ctx)

    const tileW = ctx.canvas.width * 0.117 * 1.3
    const tileH = ctx.canvas.width * 0.0703 * 1.3

    gameMap.updateTileSize(tileW, tileH)
    gameMap.updateMapOffset(offsetX, offsetY)

    gameMap.updateGameObjects()

    gameMap.drawTiles(ctx)
    gameMap.drawGameObjects(ctx)
  }

  updateCanvasSize (ctx: CanvasRenderingContext2D) {
    ctx.canvas.width = window.innerWidth > 1920 ? 1920 : window.innerWidth
    ctx.canvas.height = window.innerHeight

    if (window.devicePixelRatio > 1) {
      const canvasWidth = ctx.canvas.width
      const canvasHeight = ctx.canvas.height

      ctx.canvas.width = canvasWidth * window.devicePixelRatio
      ctx.canvas.height = canvasHeight * window.devicePixelRatio
      ctx.canvas.style.width = canvasWidth + 'px'
      ctx.canvas.style.height = canvasHeight + 'px'
    }
  }

  async centerGame () {
    const canvas = document.getElementById('main') as HTMLCanvasElement
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

    this.canvas = canvas
    this.ctx = ctx

    ctx.imageSmoothingEnabled = true

    this.updateCanvasSize(ctx)

    const tileW = canvas.width * 0.117 * 1.3
    const tileH = canvas.width * 0.0703 * 1.3

    let offsetX = ctx.canvas.width / 2 - tileW / 2
    let offsetY = (ctx.canvas.height - tileH * this.mapHeight) / 2

    this.gameMap = new GameMap(tileW, tileH, this.mapWidth, this.mapHeight, offsetX, offsetY)

    for (let row = 0; row < this.mapHeight; row++) {
      for (let column = 0; column < this.mapWidth; column++) {
        if ((row >= 6 && row <= 9) && (column >= 6 && column <= 9)) {
          this.gameMap.addTile(this.tileImages.getTileByFileName('road.png'), row, column, (row + 1) * column, true)
        } else {
          this.gameMap.addTile(this.tileImages.getTileByFileName(`background_tile_${Math.floor(Math.random() * 11) + 1}.png`), row, column, 0, false)
        }
      }
    }

    this.buildings = [
      {
        title: 'ЖК',
        description: 'Традиционный жилой комплекс со&nbsp;всей необходимой инфраструктурой  для&nbsp;комфортной жизни',
        type: 'living',
        posX: 0,
        posY: 0,
        x: 0,
        y: 0,
        z: 0,
        centerX: 0,
        centerY: 0,
        isInCell: Tile.prototype.isInCell,
        tileImage: this.tileImages.getTileByFileName('residential_big.png'),
        w: canvas.width * 0.1 * 1.3,
        h: canvas.width * 0.0703 * 1.3,
        offsetX: tileW * 0.06,
        offsetY: tileH * -0.07,
        canBeOverflown: false,
        onRefresh: function (obj: GameObject, map: GameMap) {
          obj.w = canvas.width * 0.1 * 1.3
          obj.h = canvas.width * 0.0703 * 1.3
          obj.offsetX = map.tileWidth * 0.06
          obj.offsetY = map.tileHeight * -0.07

          obj.centerX = obj.x + obj.w * 0.5
          obj.centerY = obj.y + obj.h * 0.5
        }
      },
      {
        title: 'Дом',
        description: 'Многоквартирное здание с&nbsp;уютным двором и&nbsp;дружными соседями',
        type: 'living',
        posX: 0,
        posY: 0,
        x: 0,
        y: 0,
        z: 0,
        centerX: 0,
        centerY: 0,
        isInCell: Tile.prototype.isInCell,
        tileImage: this.tileImages.getTileByFileName('residential_small.png'),
        w: canvas.width * 0.1 * 1.3,
        h: canvas.width * 0.07 * 1.3,
        offsetX: tileW * 0.07,
        offsetY: tileH * -0.045,
        canBeOverflown: false,
        onRefresh: function (obj: GameObject, map: GameMap) {
          obj.w = canvas.width * 0.1 * 1.3
          obj.h = canvas.width * 0.07 * 1.3
          obj.offsetX = map.tileWidth * 0.07
          obj.offsetY = map.tileHeight * -0.045

          obj.centerX = obj.x + obj.w * 0.5
          obj.centerY = obj.y + obj.h * 0.5
        }
      },
      {
        title: 'Аквапарк',
        description: 'Водные горки и&nbsp;экстремальные виражи для&nbsp;самых смелых<br/>и отважных',
        type: 'social',
        posX: 0,
        posY: 0,
        x: 0,
        y: 0,
        z: 0,
        centerX: 0,
        centerY: 0,
        isInCell: Tile.prototype.isInCell,
        tileImage: this.tileImages.getTileByFileName('water_park.png'),
        w: canvas.width * 0.1 * 1.3,
        h: canvas.width * 0.0703 * 1.3,
        offsetX: tileW * 0.06,
        offsetY: tileH * -0.05,
        canBeOverflown: false,
        onRefresh: function (obj: GameObject, map: GameMap) {
          obj.w = canvas.width * 0.1 * 1.3
          obj.h = canvas.width * 0.0703 * 1.3
          obj.offsetX = map.tileWidth * 0.06
          obj.offsetY = map.tileHeight * -0.05

          obj.centerX = obj.x + obj.w * 0.5
          obj.centerY = obj.y + obj.h * 0.5
        }
      },
      {
        title: 'Детский сад',
        description: 'Место социализации и&nbsp;досуга для&nbsp;самых маленьких жителей квартала',
        type: 'social',
        posX: 0,
        posY: 0,
        x: 0,
        y: 0,
        z: 0,
        centerX: 0,
        centerY: 0,
        isInCell: Tile.prototype.isInCell,
        tileImage: this.tileImages.getTileByFileName('kindergarten.png'),
        w: canvas.width * 0.1 * 1.3,
        h: canvas.width * 0.0703 * 1.3,
        offsetX: tileW * 0.07,
        offsetY: tileH * -0.08,
        canBeOverflown: false,
        onRefresh: function (obj: GameObject, map: GameMap) {
          obj.w = canvas.width * 0.1 * 1.3
          obj.h = canvas.width * 0.0703 * 1.3
          obj.offsetX = map.tileWidth * 0.07
          obj.offsetY = map.tileHeight * -0.07

          obj.centerX = obj.x + obj.w * 0.5
          obj.centerY = obj.y + obj.h * 0.5
        }
      },
      {
        title: 'Спорткомплекс',
        description: 'Дорожки для бега, футбольное поле и хоккейная коробка — все необходимое для занятия любимым видом&nbsp;спорта ',
        type: 'social',
        posX: 0,
        posY: 0,
        x: 0,
        y: 0,
        z: 0,
        centerX: 0,
        centerY: 0,
        isInCell: Tile.prototype.isInCell,
        tileImage: this.tileImages.getTileByFileName('sports.png'),
        w: canvas.width * 0.1 * 1.3,
        h: canvas.width * 0.06 * 1.3,
        offsetX: tileW * 0.07,
        offsetY: tileH * 0.07,
        canBeOverflown: false,
        onRefresh: function (obj: GameObject, map: GameMap) {
          obj.w = canvas.width * 0.1 * 1.3
          obj.h = canvas.width * 0.06 * 1.3
          obj.offsetX = map.tileWidth * 0.07
          obj.offsetY = map.tileHeight * 0.07

          obj.centerX = obj.x + obj.w * 0.5
          obj.centerY = obj.y + obj.h * 0.5
        }
      },
      {
        title: 'Станция метро',
        description: 'Аутентичный вестибюль и&nbsp;новые технологичные поезда метро быстро доставят до&nbsp;центра',
        type: 'social',
        posX: 0,
        posY: 0,
        x: 0,
        y: 0,
        z: 0,
        centerX: 0,
        centerY: 0,
        isInCell: Tile.prototype.isInCell,
        tileImage: this.tileImages.getTileByFileName('metro.png'),
        w: canvas.width * 0.1 * 1.3,
        h: canvas.width * 0.0703 * 1.3,
        offsetX: tileW * 0.07,
        offsetY: tileH * -0.08,
        canBeOverflown: false,
        onRefresh: function (obj: GameObject, map: GameMap) {
          obj.w = canvas.width * 0.1 * 1.3
          obj.h = canvas.width * 0.0703 * 1.3
          obj.offsetX = map.tileWidth * 0.07
          obj.offsetY = map.tileHeight * -0.07

          obj.centerX = obj.x + obj.w * 0.5
          obj.centerY = obj.y + obj.h * 0.5
        }
      },
      {
        title: 'Поликлиника',
        description: 'Городская поликлиника с&nbsp;бесплатным медицинским обслуживанием',
        type: 'social',
        posX: 0,
        posY: 0,
        x: 0,
        y: 0,
        z: 0,
        centerX: 0,
        centerY: 0,
        isInCell: Tile.prototype.isInCell,
        tileImage: this.tileImages.getTileByFileName('hospital.png'),
        w: canvas.width * 0.1 * 1.3,
        h: canvas.width * 0.0703 * 1.3,
        offsetX: tileW * 0.07,
        offsetY: tileH * -0.08,
        canBeOverflown: false,
        onRefresh: function (obj: GameObject, map: GameMap) {
          obj.w = canvas.width * 0.1 * 1.3
          obj.h = canvas.width * 0.0703 * 1.3
          obj.offsetX = map.tileWidth * 0.07
          obj.offsetY = map.tileHeight * -0.07

          obj.centerX = obj.x + obj.w * 0.5
          obj.centerY = obj.y + obj.h * 0.5
        }
      },
      {
        title: 'Зеленая зона',
        description: 'Место отдыха и&nbsp;уединения с&nbsp;природой — есть велосипедные дорожки и&nbsp;лужайки для&nbsp;занятий<br/>йогой',
        type: 'social',
        posX: 0,
        posY: 0,
        x: 0,
        y: 0,
        z: 0,
        centerX: 0,
        centerY: 0,
        isInCell: Tile.prototype.isInCell,
        tileImage: this.tileImages.getTileByFileName('green_park.png'),
        w: canvas.width * 0.1 * 1.3,
        h: canvas.width * 0.06 * 1.3,
        offsetX: tileW * 0.07,
        offsetY: tileH * 0.07,
        canBeOverflown: false,
        onRefresh: function (obj: GameObject, map: GameMap) {
          obj.w = canvas.width * 0.1 * 1.3
          obj.h = canvas.width * 0.06 * 1.3
          obj.offsetX = map.tileWidth * 0.07
          obj.offsetY = map.tileHeight * 0.07

          obj.centerX = obj.x + obj.w * 0.5
          obj.centerY = obj.y + obj.h * 0.5
        }
      },
      {
        title: 'Небоскреб',
        description: 'Небоскреб — большая экосистема. Апартаменты, офисы и&nbsp;кафе — все в&nbsp;одном здании',
        type: 'living',
        posX: 0,
        posY: 0,
        x: 0,
        y: 0,
        z: 0,
        centerX: 0,
        centerY: 0,
        isInCell: Tile.prototype.isInCell,
        tileImage: this.tileImages.getTileByFileName('skyscraper.png'),
        w: canvas.width * 0.1 * 1.3,
        h: canvas.width * 0.13 * 1.3,
        offsetX: tileW * 0.07,
        offsetY: -tileH * 0.9,
        canBeOverflown: false,
        onRefresh: function (obj: GameObject, map: GameMap) {
          obj.w = canvas.width * 0.1 * 1.3
          obj.h = canvas.width * 0.13 * 1.3
          obj.offsetX = map.tileWidth * 0.07
          obj.offsetY = -map.tileHeight * 0.9

          obj.centerX = obj.x + obj.w * 0.5
          obj.centerY = obj.y + obj.h * 0.5
        }
      },
      {
        title: 'Школа',
        description: 'Современная школа в&nbsp;шаговой доступности для&nbsp;юных жителей<br/>квартала',
        type: 'social',
        posX: 0,
        posY: 0,
        x: 0,
        y: 0,
        z: 0,
        centerX: 0,
        centerY: 0,
        isInCell: Tile.prototype.isInCell,
        tileImage: this.tileImages.getTileByFileName('school.png'),
        w: canvas.width * 0.1 * 1.3,
        h: canvas.width * 0.07 * 1.3,
        offsetX: tileW * 0.08,
        offsetY: tileH * -0.04,
        canBeOverflown: false,
        onRefresh: function (obj: GameObject, map: GameMap) {
          obj.w = canvas.width * 0.1 * 1.3
          obj.h = canvas.width * 0.07 * 1.3
          obj.offsetX = map.tileWidth * 0.08
          obj.offsetY = map.tileHeight * -0.04

          obj.centerX = obj.x + obj.w * 0.5
          obj.centerY = obj.y + obj.h * 0.5
        }
      },
      {
        title: 'Завод',
        description: 'Hi-Tech-производство на&nbsp;150&nbsp;рабочих мест: все&nbsp;роботизировано и&nbsp;автоматизировано',
        type: 'work',
        posX: 0,
        posY: 0,
        x: 0,
        y: 0,
        z: 0,
        centerX: 0,
        centerY: 0,
        isInCell: Tile.prototype.isInCell,
        tileImage: this.tileImages.getTileByFileName('innovative_industry.png'),
        // hoverTileImage: this.tileImages.getTileByFileName('innovative_industry_hover.png'),
        w: canvas.width * 0.1 * 1.3,
        h: canvas.width * 0.07 * 1.3,
        offsetX: tileW * 0.07,
        offsetY: tileH * -0.045,
        canBeOverflown: false,
        onRefresh: function (obj: GameObject, map: GameMap) {
          obj.w = canvas.width * 0.1 * 1.3
          obj.h = canvas.width * 0.07 * 1.3
          obj.offsetX = map.tileWidth * 0.07
          obj.offsetY = map.tileHeight * -0.045

          obj.centerX = obj.x + obj.w * 0.5
          obj.centerY = obj.y + obj.h * 0.5
        }
      },
      {
        title: 'Фабрика',
        description: 'Небольшое экологически чистое производство на&nbsp;200&nbsp;рабочих мест. Производит необходимые&nbsp;товары для&nbsp;жителей города',
        type: 'work',
        posX: 0,
        posY: 0,
        x: 0,
        y: 0,
        z: 0,
        centerX: 0,
        centerY: 0,
        isInCell: Tile.prototype.isInCell,
        tileImage: this.tileImages.getTileByFileName('small_industry.png'),
        // hoverTileImage: this.tileImages.getTileByFileName('small_industry_hover.png'),
        w: canvas.width * 0.1 * 1.3,
        h: canvas.width * 0.0703 * 1.3,
        offsetX: tileW * 0.06,
        offsetY: tileH * -0.07,
        canBeOverflown: false,
        onRefresh: function (obj: GameObject, map: GameMap) {
          obj.w = canvas.width * 0.1 * 1.3
          obj.h = canvas.width * 0.0703 * 1.3
          obj.offsetX = map.tileWidth * 0.06
          obj.offsetY = map.tileHeight * -0.07

          obj.centerX = obj.x + obj.w * 0.5
          obj.centerY = obj.y + obj.h * 0.5
        }
      },
      {
        title: 'Торговый центр',
        description: 'В&nbsp;одном здании все необходимые магазины для&nbsp;полноценного шопинга',
        type: 'social',
        posX: 0,
        posY: 0,
        x: 0,
        y: 0,
        z: 0,
        centerX: 0,
        centerY: 0,
        isInCell: Tile.prototype.isInCell,
        tileImage: this.tileImages.getTileByFileName('mall.png'),
        w: canvas.width * 0.1 * 1.3,
        h: canvas.width * 0.0703 * 1.3,
        offsetX: tileW * 0.07,
        offsetY: tileH * -0.08,
        canBeOverflown: false,
        onRefresh: function (obj: GameObject, map: GameMap) {
          obj.w = canvas.width * 0.1 * 1.3
          obj.h = canvas.width * 0.0703 * 1.3
          obj.offsetX = map.tileWidth * 0.07
          obj.offsetY = map.tileHeight * -0.07

          obj.centerX = obj.x + obj.w * 0.5
          obj.centerY = obj.y + obj.h * 0.5
        }
      },
      {
        title: 'Технопарк',
        description: 'Инновационные<br/>разработки, высокотехнологичное<br/>производство, особый<br/>статус резидентов и почти<br/>2000 рабочих мест',
        type: 'work',
        posX: 0,
        posY: 0,
        x: 0,
        y: 0,
        z: 0,
        centerX: 0,
        centerY: 0,
        isInCell: Tile.prototype.isInCell,
        tileImage: this.tileImages.getTileByFileName('technopolis.png'),
        w: canvas.width * 0.1 * 1.3,
        h: canvas.width * 0.0703 * 1.3,
        offsetX: tileW * 0.07,
        offsetY: tileH * -0.08,
        canBeOverflown: false,
        onRefresh: function (obj: GameObject, map: GameMap) {
          obj.w = canvas.width * 0.1 * 1.3
          obj.h = canvas.width * 0.0703 * 1.3
          obj.offsetX = map.tileWidth * 0.07
          obj.offsetY = map.tileHeight * -0.07

          obj.centerX = obj.x + obj.w * 0.5
          obj.centerY = obj.y + obj.h * 0.5
        }
      }
    ]

    this.gameMap.drawTiles(ctx)
    this.gameMap.drawGameObjects(ctx)

    window.addEventListener('resize', () => {
      this.updateGame(ctx, this.gameMap, offsetX, offsetY)
    })

    window.addEventListener('orientationchange', () => {
      this.updateGame(ctx, this.gameMap, offsetX, offsetY)
    })

    canvas.addEventListener('mousedown', (e) => {
      this.hideFirstHint()

      this.setState({
        mouseDown: true,
        lastMouseX: e.clientX,
        lastMouseY: e.clientY
      })
    })

    canvas.addEventListener('mouseup', (e) => {
      const seconds = (new Date().getTime() - this.state.touchLastDate.getTime()) / 1000
      if (seconds < 0.3) {
        return
      }

      if (!this.state.mouseMove) {
        this.selectCell(e, ctx, this.gameMap)
      }

      this.setState({
        mouseDown: false,
        mouseMove: false,
        touchLastDate: new Date()
      })
    })

    canvas.addEventListener('mousemove', (e) => {
      if (!this.state.mouseMove) {
        this.hoverRoadTile(e, this.ctx, this.gameMap, this.tileImages)
      }

      const newLocationMouse = e.clientX + e.clientY
      const oldLocationMouse = this.state.lastMouseX + this.state.lastMouseY

      if (Math.abs(newLocationMouse) - Math.abs(oldLocationMouse) > 5 || Math.abs(oldLocationMouse) - Math.abs(newLocationMouse) > 5) {
        if (e.buttons === 1) {
          offsetX -= this.state.lastMouseX - e.clientX
          offsetY -= this.state.lastMouseY - e.clientY
          this.setState({
            lastMouseX: e.clientX,
            lastMouseY: e.clientY,
            mouseMove: true
          })

          this.updateGame(ctx, this.gameMap, offsetX, offsetY)
        }
      }
    })

    // mobile support
    canvas.addEventListener('touchstart', (e) => {
      e.preventDefault()

      if (e.touches.length !== 1) {
        return
      }

      this.hideFirstHint()

      this.setState({
        mouseDown: true,
        lastMouseX: e.touches[0].clientX,
        lastMouseY: e.touches[0].clientY
      })
    })

    canvas.addEventListener('touchmove', (e) => {
      e.preventDefault()

      if (e.touches.length !== 1) {
        return
      }

      const newLocationMouse = e.touches[0].clientX + e.touches[0].clientY
      const oldLocationMouse = this.state.lastMouseX + this.state.lastMouseY

      if (Math.abs(newLocationMouse) - Math.abs(oldLocationMouse) > 5 || Math.abs(oldLocationMouse) - Math.abs(newLocationMouse) > 5) {
        offsetX -= this.state.lastMouseX - e.touches[0].clientX
        offsetY -= this.state.lastMouseY - e.touches[0].clientY
        this.setState({
          lastMouseX: e.touches[0].clientX,
          lastMouseY: e.touches[0].clientY,
          mouseMove: true
        })

        this.updateGame(ctx, this.gameMap, offsetX, offsetY)
      }
    })

    canvas.addEventListener('touchend', (e) => {
      const seconds = (new Date().getTime() - this.state.touchLastDate.getTime()) / 1000
      if (seconds < 0.3) {
        return
      }

      e.preventDefault()

      if (!this.state.mouseMove) {
        this.hoverRoadTile(e, this.ctx, this.gameMap, this.tileImages)
        this.selectCell(e, ctx, this.gameMap)
      }

      this.setState({
        mouseDown: false,
        mouseMove: false,
        touchLastDate: new Date()
      })
    })
  }

  // Функционал выбора и постройки зданий

  selectBuilding (e: KeyboardEvent) {
    try {
      const num = parseInt(e.key)
      if (num > this.buildings.length || num < 1) {
        return
      }
      this.setState({
        currentBuilding: num
      })
    } catch (e) {
      console.log(e)
    }
  }

  addBuildingOnClick (e: MouseEvent, ctx: CanvasRenderingContext2D, gameMap: GameMap) {
    const r = ctx.canvas.getBoundingClientRect()
    const x = (e.clientX - r.left) * window.devicePixelRatio
    const y = (e.clientY - r.top) * window.devicePixelRatio

    gameMap.tilesArray.forEach((row, rowIndex) => {
      row.forEach((tile, columnIndex) => {
        if (!tile?.isInCell(x, y) || !tile?.canBeOverflown) {
          return
        }

        gameMap.pushGameObject(new GameObject(this.buildings[this.state.currentBuilding].tileImage, columnIndex, rowIndex, 0, 0, this.buildings[this.state.currentBuilding].w, this.buildings[this.state.currentBuilding].h, (rowIndex + 1) * columnIndex, this.buildings[this.state.currentBuilding].offsetX, this.buildings[this.state.currentBuilding].offsetY, this.buildings[this.state.currentBuilding].onRefresh))
      })
    })

    this.updateGame(ctx, gameMap, gameMap.mapOffsetX, gameMap.mapOffsetY)
  }

  hoverRoadTile (e: MouseEvent | TouchEvent, ctx: CanvasRenderingContext2D, gameMap: GameMap, tileImages: TileImageFactory) {
    const r = ctx.canvas.getBoundingClientRect()

    const clientX = e instanceof MouseEvent ? e.clientX : e.touches.length > 0 ? e.touches[0].clientX : this.state.lastMouseX
    const clientY = e instanceof MouseEvent ? e.clientY : e.touches.length > 0 ? e.touches[0].clientY : this.state.lastMouseY

    const x = (clientX - r.left) * window.devicePixelRatio
    const y = (clientY - r.top) * window.devicePixelRatio

    if (this.state.selectedCell) {
      return
    }

    gameMap.tilesArray.forEach((row, rowIndex) => {
      row.forEach((tile, columnIndex) => {
        if (!tile?.isInCell(x, y) || !tile?.canBeOverflown) {
          if (tile.tileImage === this.tileImages.getTileByFileName('road_selected.png')) {
            gameMap.tilesArray[rowIndex][columnIndex].tileImage = this.tileImages.getTileByFileName('road.png')

            if (gameMap.objectsArray[rowIndex][columnIndex]) {
              if (gameMap.objectsArray[rowIndex][columnIndex]?.tileImage.imagePath.includes('_hover.png')) {
                // @ts-ignore
                gameMap.objectsArray[rowIndex][columnIndex].tileImage = this.tileImages.getTileImageByPath(gameMap.objectsArray[rowIndex][columnIndex].tileImage.imagePath.replace('_hover.png', '.png'))
              }
            }
          }
          return
        }

        gameMap.tilesArray[rowIndex][columnIndex].tileImage = this.tileImages.getTileByFileName('road_selected.png')

        if (gameMap.objectsArray[rowIndex][columnIndex]) {
          // @ts-ignore
          if (!gameMap.objectsArray[rowIndex][columnIndex].tileImage.imagePath.includes('_hover.png')) {
            // @ts-ignore
            gameMap.objectsArray[rowIndex][columnIndex].tileImage = this.tileImages.getTileImageByPath(gameMap.objectsArray[rowIndex][columnIndex].tileImage.imagePath.replace('.png', '_hover.png'))
          }
        }
      })
    })

    this.updateGame(ctx, gameMap, gameMap.mapOffsetX, gameMap.mapOffsetY)
  }

  addBuildingToSelectedCell (selectedBuilding: GameObject) {
    this.setState({
      selectKey: Math.random()
    })

    const obj = this.gameMap.objectsArray[this.state.selectedCell.posY][this.state.selectedCell.posX]
    if (obj) {
      this.gameMap.secretGameObjectsQueue.splice(this.gameMap.secretGameObjectsQueue.indexOf(obj), 1)
      this.gameMap.objectsArray[this.state.selectedCell.posY][this.state.selectedCell.posX] = null
    }
    this.gameMap.pushGameObject(new GameObject(selectedBuilding.tileImage, this.state.selectedCell.posX, this.state.selectedCell.posY, 0, 0, selectedBuilding.w, selectedBuilding.h, (this.state.selectedCell.posY + 1) * this.state.selectedCell.posX, selectedBuilding.offsetX, selectedBuilding.offsetY, selectedBuilding.onRefresh))

    this.updateGame(this.ctx, this.gameMap, this.gameMap.mapOffsetX, this.gameMap.mapOffsetY)

    this.setState({
      showBuildingsList: false,
      selectedCell: null
    })

    this.soundsRef.current?.playBuildSound()
  }

  removeBuildingOnSelectedCell () {
    const obj = this.gameMap.objectsArray[this.state.selectedCell.posY][this.state.selectedCell.posX]
    this.gameMap.secretGameObjectsQueue.splice(this.gameMap.secretGameObjectsQueue.indexOf(obj), 1)
    this.gameMap.objectsArray[this.state.selectedCell.posY][this.state.selectedCell.posX] = null

    this.updateGame(this.ctx, this.gameMap, this.gameMap.mapOffsetX, this.gameMap.mapOffsetY)

    this.setState({
      selectedCell: null,
      showActionsList: false
    })

    document.dispatchEvent(new Event('play_button_sound'))
  }

  removeBuildingOnClick (e: MouseEvent, ctx: CanvasRenderingContext2D, gameMap: GameMap) {
    e.preventDefault()
    const r = ctx.canvas.getBoundingClientRect()
    const x = (e.clientX - r.left) * window.devicePixelRatio
    const y = (e.clientY - r.top) * window.devicePixelRatio

    gameMap.tilesArray.forEach((row, rowIndex) => {
      row.forEach((tile, columnIndex) => {
        if (!tile?.isInCell(x, y)) {
          return
        }

        gameMap.objectsArray[rowIndex][columnIndex] = null
      })
    })

    this.updateGame(ctx, gameMap, gameMap.mapOffsetX, gameMap.mapOffsetY)
  }

  selectCell (e: MouseEvent | TouchEvent, ctx: CanvasRenderingContext2D, gameMap: GameMap) {
    const r = ctx.canvas.getBoundingClientRect()

    const clientX = e instanceof MouseEvent ? e.clientX : e.touches.length > 0 ? e.touches[0].clientX : this.state.lastMouseX
    const clientY = e instanceof MouseEvent ? e.clientY : e.touches.length > 0 ? e.touches[0].clientY : this.state.lastMouseY

    const x = (clientX - r.left) * window.devicePixelRatio
    const y = (clientY - r.top) * window.devicePixelRatio

    gameMap.tilesArray.forEach((row, rowIndex) => {
      row.forEach((tile, columnIndex) => {
        if (!tile?.isInCell(x, y) || !tile?.canBeOverflown) {
          return
        }

        if (!this.gameMap.objectsArray[rowIndex][columnIndex]) {
          this.soundsRef.current?.playChooseBuildingSound()
        }

        this.setState({
          selectedCell: tile,
          showBuildingsList: !this.gameMap.objectsArray[rowIndex][columnIndex],
          showActionsList: this.gameMap.objectsArray[rowIndex][columnIndex]
        })
      })
    })
  }

  checkIfAnyBuildingIsCreated = (gameMap: GameMap) => gameMap ? gameMap.secretGameObjectsQueue.length > 0 : false

  // очки

  findBuildingByImage (image: TileImage) {
    let res
    this.buildings.forEach((building) => {
      if (building.tileImage === image) {
        res = building
      }
    })

    return res
  }

  getSocialScores () {
    let score = 0

    try {
      this.gameMap.objectsArray.forEach((row: any[]) => {
        row.forEach((obj: any) => {
          if (obj) {
            // @ts-ignore
            if (this.findBuildingByImage(obj.tileImage).type === 'social') {
              score++
            }
          }
        })
      })
    } catch {
    }

    return score
  }

  getWorkScores () {
    let score = 0

    try {
      this.gameMap.objectsArray.forEach((row: any[]) => {
        row.forEach((obj: any) => {
          if (obj) {
            // @ts-ignore
            if (this.findBuildingByImage(obj.tileImage).type === 'work') {
              score++
            }
          }
        })
      })
    } catch {
    }

    return score
  }

  getLivingScores () {
    let score = 0
    try {
      this.gameMap.objectsArray.forEach((row: any[]) => {
        row.forEach((obj: any) => {
          if (obj) {
            // @ts-ignore
            if (this.findBuildingByImage(obj.tileImage).type === 'living') {
              score++
            }
          }
        })
      })
    } catch {
    }

    return score
  }

  //

  removeAllObjects () {
    this.setState({ showResults: false, showMenu: true })
    this.gameMap = null
    this.ctx = null
  }

  // Hints

  hideFirstHint () {
    // @ts-ignore
    document.querySelector('.popupTry').style.opacity = '0'
  }

  showFirstHint () {
    // @ts-ignore
    document.querySelector('.popupTry').style.opacity = '1'
  }

  toggleHintPopup () {
    const popup = document.querySelector('.popupHintBg') as HTMLElement

    if (parseInt(popup.style.opacity)) {
      popup.style.opacity = '0'
      setTimeout(() => {
        popup.style.display = 'none'
      }, 200)
    } else {
      popup.style.display = 'block'
      setTimeout(() => {
        popup.style.opacity = '1'
      }, 200)
    }
  }

  // DOM

  render () {
    return (
            <div className={'container'}>
                <LoadingScreen onComplete={() => {
                  this.setState({ showLoading: false, showMenu: true })
                }}
                               style={{ display: this.state.showLoading ? '' : 'none' }}/>
                <Menu start={() => {
                  document.dispatchEvent(new Event('play_button_sound'))
                  this.setState({ showMenu: false, restart: true })
                }}
                      style={{ display: this.state.showMenu ? '' : 'none' }}/>
                <CityBuilder start={async () => {
                  document.dispatchEvent(new Event('play_button_sound'))
                  await this.centerGame()
                  this.setState({ restart: false, showGame: true })
                }}
                             style={{ display: this.state.restart ? '' : 'none' }}/>
                <canvas id="main" style={{ display: this.state.showGame ? '' : 'none' }}/>

                <RotateScreen/>

                <div style={{
                  display: this.state.showActionsList ? '' : 'none',
                  left: this.state.selectedCell ? `${this.state.selectedCell.centerX / window.devicePixelRatio}px` : '0',
                  top: this.state.selectedCell ? `${this.state.selectedCell.y / window.devicePixelRatio}px` : '0'
                }} className={'actionsButtons'}>
                    <div onClick={() => this.removeBuildingOnSelectedCell()} className={'button'}>Удалить</div>
                    <div onClick={() => {
                      this.setState({ showBuildingsList: true, showActionsList: false })
                      this.soundsRef.current?.playButtonSound()
                    }}
                         className={'button'}>Заменить
                    </div>
                </div>

                <BuildCityButton style={{ display: this.state.showGame && !this.state.showBuildingsList ? '' : 'none' }}
                                 onclick={() => {
                                   this.setState({ showResults: true, showGame: false })
                                   this.resultsRef.current?.playEndingMusic()
                                 }} active={this.checkIfAnyBuildingIsCreated(this.gameMap)}/>

                <div key={this.state.selectKey} className={'selectContainer'}
                     style={{ display: this.state.showBuildingsList ? '' : 'none' }}>
                    <div className={'selectChild'}>
                        <Dropdown text={'Жилье'}>
                            <div className={'buildingCardContainer'}>
                                {
                                    this.buildings.map((building, index) => {
                                      return building.type === 'living'
                                        ? <div onClick={() => this.addBuildingToSelectedCell(building)} key={index}
                                                   className={'buildingCard'}>
                                                <h3>{building.title}</h3>
                                                <img src={building.tileImage.image.src} alt={''}/>
                                                <p dangerouslySetInnerHTML={{ __html: building.description }}/>
                                            </div>
                                        : ''
                                    })
                                }
                            </div>
                        </Dropdown>
                    </div>

                    <div className={'selectChild'}>
                        <Dropdown text={'Социальные объекты'}>
                            <div className={'buildingCardContainer'}>
                                {
                                    this.buildings.map((building, index) => {
                                      return building.type === 'social'
                                        ? <div onClick={() => this.addBuildingToSelectedCell(building)} key={index}
                                                   className={'buildingCard'}>
                                                <h3>{building.title}</h3>
                                                <img src={building.tileImage.image.src} alt={''}/>
                                                <p dangerouslySetInnerHTML={{ __html: building.description }}/>
                                            </div>
                                        : ''
                                    })
                                }
                            </div>
                        </Dropdown>
                    </div>

                    <div className={'selectChild'}>
                        <Dropdown text={'Промышленность'}>
                            <div className={'buildingCardContainer'}>
                                {
                                    this.buildings.map((building, index) => {
                                      return building.type === 'work'
                                        ? <div onClick={() => this.addBuildingToSelectedCell(building)} key={index}
                                                   className={'buildingCard'}>
                                                <h3>{building.title}</h3>
                                                <img src={building.tileImage.image.src} alt={''}/>
                                                <p dangerouslySetInnerHTML={{ __html: building.description }}/>
                                            </div>
                                        : ''
                                    })
                                }
                            </div>
                        </Dropdown>
                    </div>
                </div>

                <div style={{ display: this.state.showGame ? '' : 'none' }} className={'popupTry'}>
                    Нажми на клетку<br/>и выбери здание
                </div>

                <div style={{ display: this.state.showGame && !this.state.showBuildingsList ? '' : 'none' }}
                     onClick={() => {
                       this.setState({ showResults: false, showGame: false })
                       this.removeAllObjects()
                       document.dispatchEvent(new Event('play_button_sound'))
                       this.showFirstHint()
                     }} className={'buttonRoundWhiteSmall buttonHome'}>
                    <img alt={''} src={'/home_icon.png'} width={'100%'} height={'100%'}/>
                </div>

                <div style={{ display: this.state.showGame && !this.state.showBuildingsList ? '' : 'none' }}
                     onClick={() => this.toggleHintPopup()} className={'buttonRoundWhiteSmall buttonHint'}>
                    <svg width="100%" height="100%" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M22.4999 0C10.1157 0 0 10.1157 0 22.4999C0 34.884 10.1157 44.9997 22.4999 44.9997C34.9453 45.061 45.061 34.9453 45.061 22.4999C45.061 10.0544 34.9453 0 22.4999 0ZM22.4999 41.9957C11.771 41.9957 3.06538 33.2287 3.06538 22.4999C3.06538 11.771 11.771 3.06538 22.4999 3.06538C33.2287 3.06538 41.9343 11.771 41.9343 22.4999C41.9343 33.2287 33.2287 41.9957 22.4999 41.9957ZM26.117 11.8937C24.0325 10.5449 21.2124 10.5449 19.0053 11.955C17.0435 13.1811 16.0626 15.2043 16.3691 17.35C16.4917 18.2083 17.2887 18.7601 18.0857 18.6375C18.944 18.5149 19.4958 17.7179 19.3732 16.9209C19.1893 15.6334 20.1702 14.8364 20.5993 14.5912C21.5802 13.9781 23.1129 13.6716 24.3391 14.5299C25.0748 15.0203 25.5039 15.756 25.5039 16.6143C25.5652 17.7179 24.8909 18.8827 23.726 19.741C20.2928 22.3159 20.4767 24.7682 20.5993 26.4235C20.5993 26.6688 20.5993 26.8527 20.5993 27.0979C20.5993 27.9562 21.2737 28.6306 22.132 28.6306C22.9903 28.6306 23.6647 27.9562 23.6647 27.0979C23.6647 26.8527 23.6647 26.5462 23.6034 26.2396C23.5421 24.8295 23.4808 23.7873 25.5039 22.1933C27.4658 20.7219 28.5693 18.6375 28.508 16.4917C28.6306 14.5912 27.711 12.9359 26.117 11.8937ZM22.4999 29.5502C21.335 29.5502 20.3541 30.5311 20.3541 31.696V31.8186C20.3541 32.9834 21.335 33.9644 22.4999 33.9644C23.6647 33.9644 24.6456 32.9834 24.6456 31.8186V31.696C24.6456 30.5311 23.726 29.5502 22.4999 29.5502Z"
                            fill="#D4172A"/>
                    </svg>
                </div>

                <MusicPlayer style={{ display: this.state.showGame ? '' : 'none' }}/>

                <div onClick={() => this.toggleHintPopup()}
                     style={{ display: this.state.showGame ? '' : 'none', opacity: '0' }}
                     className={'popupBg popupHintBg'}>
                    <div className={'popupHint'}>
                        <div style={{ position: 'relative' }}>
                            <svg className={'popupHintClose'} width="17" height="17" viewBox="0 0 17 17" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.4883 8.59942L16.5526 2.53509C17.1491 1.9386 17.1491 1.04386 16.5526 0.447368C15.9561 -0.149123 15.0614 -0.149123 14.4649 0.447368L8.40058 6.5117L2.6345 0.745614C2.03801 0.149123 1.04386 0.149123 0.447368 0.745614C-0.149123 1.34211 -0.149123 2.23684 0.447368 2.83333L6.21345 8.59942L0.447368 14.3655C-0.149123 14.962 -0.149123 15.8567 0.447368 16.4532C0.745614 16.8509 1.14327 16.9503 1.54094 16.9503C1.9386 16.9503 2.33626 16.8509 2.6345 16.5526L8.40058 10.7865L14.1667 16.5526C14.4649 16.8509 14.8626 16.9503 15.2602 16.9503C15.6579 16.9503 16.0556 16.8509 16.3538 16.5526C16.9503 15.9561 16.9503 15.0614 16.3538 14.4649L10.4883 8.59942Z"
                                    fill="currentColor"/>
                            </svg>

                        </div>

                        <div className={'popupHintText'}>
                            <br/>
                            Дано: территория заброшенной промзоны в Москве.<br/>
                            Задача: спроектировать комфортный квартал для жизни, отдыха и работы.
                            <br/>
                            <br/>
                            Придерживайтесь оптимального баланса:<br/>
                            жилье — 25%<br/>
                            промышленность — 25%<br/>
                            социальные объекты — 50%<br/>
                            <br/>
                            Покажите, каким должен быть идеальный квартал<br/>в миниатюре!
                        </div>
                    </div>
                </div>

                <Result ref={this.resultsRef} back={() => {
                  this.removeAllObjects()
                  this.resultsRef.current?.pauseEndingMusic()
                  document.dispatchEvent(new Event('play_button_sound'))
                  this.showFirstHint()
                }}
                        social={this.getSocialScores()}
                        living={this.getLivingScores()} work={this.getWorkScores()}
                        style={{ display: this.state.showResults ? '' : 'none' }}/>
                <SoundsLoader ref={this.soundsRef}/>
            </div>
    )
  }
}

export default App
